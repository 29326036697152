<template>
    <v-layout>
        <v-flex
            xs12
            md8
            offset-md2
            mt-5>
            <v-card
                light
                class="wrap-content gray-icons">
                <v-toolbar
                    dark
                    color="white"
                    class="first-line-toolbar">
                    <v-toolbar-title class="light-blue--text title-block title pb-1">
                        Tag Manager To Event Flow
                    </v-toolbar-title>
                    <v-spacer />
                </v-toolbar>
                <v-card-text>
                    <v-container
                        fluid
                        grid-list-md>
                        <v-stepper
                            v-model="step"
                            light>
                            <v-stepper-header>
                                <v-stepper-step
                                    step="1"
                                    :complete="step > 1">
                                    Select dealer
                                </v-stepper-step>
                                <v-divider />
                                <v-stepper-step
                                    step="2"
                                    :complete="step > 2">
                                    Build Event Flow
                                </v-stepper-step>
                            </v-stepper-header>
                            <v-stepper-items>
                                <v-stepper-content step="1">
                                    <v-form class="px-2">
                                        <v-layout
                                            row
                                            class="px-5 py-1">
                                            <v-flex xs12>
                                                <dealer-picker-global />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout
                                            class="py-5 ma-0 form-button"
                                            row
                                            justify-center
                                            align-center>
                                            <v-flex xs12>
                                                <div class="text-xs-center">
                                                    <v-btn
                                                        flat
                                                        large
                                                        color="white"
                                                        @click.native="step++">
                                                        Continue
                                                    </v-btn>
                                                </div>
                                            </v-flex>
                                        </v-layout>
                                    </v-form>
                                </v-stepper-content>
                                <v-stepper-content step="2">
                                    <v-form class="px-2">
                                        <v-layout
                                            row
                                            class="px-5 py-1">
                                            <v-flex xs12>
                                                <v-list
                                                    v-for="update in updates"
                                                    :key="update.id"
                                                    light>
                                                    <v-list-tile
                                                        v-clipboard:copy="update.value"
                                                        v-clipboard:success="onCopy"
                                                        v-clipboard:error="onError"
                                                        avatar
                                                        @click.prevent>
                                                        <v-list-tile-content>
                                                            <v-list-tile-action-text>{{ update.value }}...</v-list-tile-action-text>
                                                        </v-list-tile-content>
                                                    </v-list-tile>
                                                </v-list>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout
                                            class="py-5 ma-0 form-button"
                                            row
                                            justify-center
                                            align-center>
                                            <v-flex xs12>
                                                <div class="text-xs-center">
                                                    <v-btn
                                                        flat
                                                        large
                                                        color="white"
                                                        @click.native="step--">
                                                        Back
                                                    </v-btn>
                                                    <v-btn
                                                        flat
                                                        large
                                                        color="white"
                                                        @click.native="buildContainer()">
                                                        Build Container
                                                    </v-btn>
                                                </div>
                                            </v-flex>
                                        </v-layout>
                                    </v-form>
                                </v-stepper-content>
                            </v-stepper-items>
                        </v-stepper>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
    import HTTP from '../../http';
    import DealerPickerGlobal from '../globals/DealerPickerGlobal';
    import { mapState } from 'vuex';
    import uuidv1 from 'uuid/v1';

    export default {
        components: {
            DealerPickerGlobal
        },
        title: 'Tag Manager To Event Flow',
        data() {
            return {
                rules: [
                    (v) => !!v || 'This field is required',
                ],
                step: null,
                updates: [],
                count: 0,
                channelId: null,
            };
        },
        computed: {
            ...mapState({
                currentDealer: (state) => state.dealer.currentDealer
            }),
        },
        created() {
            this.channelId = uuidv1();

            this.$echo.channel('gtm-channel-'+this.channelId)
                .listen('TagManagerStatusUpdated', (e) => {
                    this.updates.push({ id: this.count, value: e.update });
                    this.count++;
                    console.log(e);
                });
        },
        methods: {
            buildContainer() {
                (new HTTP)
                    .post(
                        '/dealers/'+this.currentDealer.id+'/gtm-to-eventflow',
                        {
                            channel_id: this.channelId,
                        }
                    )
                    .then(() => {
                        this.$flash('Process engaged...', 'green');
                        this.updates.push({ id: this.count, value: 'Process engaged' });
                        this.count++;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$flash(error.response.data.message, 'red');
                        this.updates.push(error.response.data.message);
                    });
            },
            onCopy(e) {
                this.$flash('\''+e.text+'\' copied to clipboard!', 'green');
            },
            onError() {
                this.$flash('Failed to copy text.', 'red');
            }
        }
    };
</script>

